import LegalLayout from "../../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../../constants/legal/security/index.md"

export default function SecurityPage() {
  return (
    <LegalLayout
      description="Keeping customer data safe and secure is a huge responsibility and a top priority for us. Here's how we make it happen."
      source={source}
      title="Security Overview"
    />
  )
}
